import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Builder from "../components/builder"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const {
    builder,
    metaTitle,
    metaDescription,
    metaImg,
  } = data.swapi.careerSingleton

  const id = typeof window !== `undefined` ? window.location.hash : ""

  useEffect(() => {
    if (id) {
      setTimeout(() => {
        scrollTo(document.querySelector(id))
      }, 100)
    }
  })

  function scrollTo(element) {
    window.scroll({
      behavior: "smooth",
      left: 0,
      top: element.offsetTop - 100,
    })
  }

  return (
    <Layout pageContext={pageContext} nameClass="page_career">
      <SEO
        title={metaTitle}
        description={metaDescription}
        img={metaImg ? metaImg.path : ""}
        lang="en"
      />
      <Builder data={builder} pageContext={pageContext} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query careerSingletonEN {
    swapi {
      careerSingleton(lang: "en") {
        builder
        metaTitle
        metaDescription
        metaImg {
          path
        }
      }
    }
  }
`
